<template>
  <v-container fluid>
    <v-row class="bg-white">
      <!-- <v-flex elevation-2> -->
        <v-row class="pa-5 primary bg-gradient" dark  align="center" height="400">
            <v-col cols="6">
              <v-img
                src="/imgs/H2.png"
                aspect-ratio="1.7"
                contain
              ></v-img>
            </v-col>
            <v-col cols="6" justify="center">
              
              <h1 class="customGreen--text ozi-font text-md-h2 font-weight-black pb-5"><span class="onyx--text ozi-font">Welcome to </span>DM Dance CO</h1>
              
              <p class="white--text d-sm-none d-md-flex">The DM Dance® concept began in 2004 and was originally developed as a pre-school syllabus only. It had become apparent that there was not an age appropriate structured dance syllabus with the result that many young dancers were suffering injuries and ending their dance careers prematurely. It was obvious that we needed to create a good solid dance foundation syllabus.</p>
            </v-col>
        </v-row>
        <v-row class="pa-10">
          <v-col>
            <h2 class="onyx--text ozi-font text-md-h2 text-center font-weight-black pb-10"><span class="customGreen--text ozi-font">About </span>DM Dance CO</h2>
            <p>The DM Dance® concept began in 2004 and was originally developed as a pre-school syllabus only. It had become apparent that there was not an age appropriate structured dance syllabus with the result that many young dancers were suffering injuries and ending their dance careers prematurely. It was obvious that we needed to create a good solid dance foundation syllabus.</p>
            <p>Two highly experienced dance teachers Tamra Cawood and Genetia King who between them have over 70 years dance experience set out to address the situation. Both have studied dance at tertiary level and have also danced locally and internationally. They both run highly successful dance studios and businesses of their own and are prominent figures in the local dance world.</p>
            <p>They set out to develop a syllabus that would not only be age appropriate but a syllabus designed specifically to strengthen and develop these young bodies thereby increasing the standard of dance in South Africa.</p>
          </v-col>
        </v-row>
        
      <!-- </v-flex> -->
    </v-row>
  </v-container>
</template>

<script>



export default {
  methods: {

    
      
  },

  computed: {

    
  },
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

</style>